import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, forwardRef, Directive, HostListener, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { applyPolyfills, defineCustomElements } from '@one/web-components/loader';

/* eslint-disable */
const _c0 = ["*"];
const fixNestedFunctionCalls = (val, self) => {
  if (val instanceof Element || val instanceof Document) {
    return val;
  } else if (Array.isArray(val)) {
    return val.map(v => fixNestedFunctionCalls(v, self));
  } else if (val === null || val === undefined) {
    return val;
  } else if (typeof val === 'object') {
    const fixedObj = {};
    for (const key of Object.keys(val)) {
      fixedObj[key] = fixNestedFunctionCalls(val[key], self);
    }
    return fixedObj;
  } else if (typeof val === 'function') {
    return (...args) => self.z.runTask(() => val(...args));
  } else {
    return val;
  }
};
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = fixNestedFunctionCalls(val, this));
      }
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  //   events.forEach(eventName => (instance[eventName] = fromEvent(el, eventName)));
  events.forEach(eventName => instance[eventName] = new EventEmitter());
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
let InternalLifecycleActivator = class InternalLifecycleActivator {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
InternalLifecycleActivator.ɵfac = function InternalLifecycleActivator_Factory(t) {
  return new (t || InternalLifecycleActivator)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
InternalLifecycleActivator.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: InternalLifecycleActivator,
  selectors: [["internal-lifecycle-activator"]],
  inputs: {
    loaded: "loaded"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function InternalLifecycleActivator_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
InternalLifecycleActivator = __decorate([ProxyCmp({
  inputs: ['loaded']
})], InternalLifecycleActivator);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InternalLifecycleActivator, [{
    type: Component,
    args: [{
      selector: 'internal-lifecycle-activator',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['loaded']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcAssistiveText = class OwcAssistiveText {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcAssistiveText.ɵfac = function OwcAssistiveText_Factory(t) {
  return new (t || OwcAssistiveText)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcAssistiveText.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcAssistiveText,
  selectors: [["owc-assistive-text"]],
  inputs: {
    anchor: "anchor",
    messages: "messages",
    status: "status",
    trigger: "trigger",
    wrap: "wrap"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcAssistiveText_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcAssistiveText = __decorate([ProxyCmp({
  inputs: ['anchor', 'messages', 'status', 'trigger', 'wrap']
})], OwcAssistiveText);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcAssistiveText, [{
    type: Component,
    args: [{
      selector: 'owc-assistive-text',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['anchor', 'messages', 'status', 'trigger', 'wrap']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcAvatar = class OwcAvatar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcAvatar.ɵfac = function OwcAvatar_Factory(t) {
  return new (t || OwcAvatar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcAvatar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcAvatar,
  selectors: [["owc-avatar"]],
  inputs: {
    disabled: "disabled",
    size: "size"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcAvatar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcAvatar = __decorate([ProxyCmp({
  inputs: ['disabled', 'size']
})], OwcAvatar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcAvatar, [{
    type: Component,
    args: [{
      selector: 'owc-avatar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'size']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcBackdrop = class OwcBackdrop {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['backdropClick']);
  }
};
OwcBackdrop.ɵfac = function OwcBackdrop_Factory(t) {
  return new (t || OwcBackdrop)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcBackdrop.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcBackdrop,
  selectors: [["owc-backdrop"]],
  inputs: {
    disableScrollLock: "disableScrollLock",
    hidden: "hidden",
    opacity: "opacity"
  },
  outputs: {
    backdropClick: "backdropClick"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcBackdrop_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcBackdrop = __decorate([ProxyCmp({
  inputs: ['disableScrollLock', 'hidden', 'opacity']
})], OwcBackdrop);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcBackdrop, [{
    type: Component,
    args: [{
      selector: 'owc-backdrop',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disableScrollLock', 'hidden', 'opacity'],
      outputs: ['backdropClick']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcBadge = class OwcBadge {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcBadge.ɵfac = function OwcBadge_Factory(t) {
  return new (t || OwcBadge)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcBadge.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcBadge,
  selectors: [["owc-badge"]],
  inputs: {
    disabled: "disabled",
    size: "size",
    status: "status"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcBadge_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcBadge = __decorate([ProxyCmp({
  inputs: ['disabled', 'size', 'status']
})], OwcBadge);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcBadge, [{
    type: Component,
    args: [{
      selector: 'owc-badge',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'size', 'status']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcBanner = class OwcBanner {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dismiss']);
  }
};
OwcBanner.ɵfac = function OwcBanner_Factory(t) {
  return new (t || OwcBanner)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcBanner.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcBanner,
  selectors: [["owc-banner"]],
  inputs: {
    direction: "direction",
    dismissIconProps: "dismissIconProps",
    elevated: "elevated",
    showClose: "showClose",
    status: "status",
    variant: "variant"
  },
  outputs: {
    dismiss: "dismiss"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcBanner_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcBanner = __decorate([ProxyCmp({
  inputs: ['direction', 'dismissIconProps', 'elevated', 'showClose', 'status', 'variant']
})], OwcBanner);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcBanner, [{
    type: Component,
    args: [{
      selector: 'owc-banner',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['direction', 'dismissIconProps', 'elevated', 'showClose', 'status', 'variant'],
      outputs: ['dismiss']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcBaseDropdown = class OwcBaseDropdown {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['clearClick', 'clickOutside', 'itemAdd', 'validityChange', 'valueChange', 'visibleChange']);
  }
};
OwcBaseDropdown.ɵfac = function OwcBaseDropdown_Factory(t) {
  return new (t || OwcBaseDropdown)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcBaseDropdown.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcBaseDropdown,
  selectors: [["owc-base-dropdown"]],
  inputs: {
    addItemOnMatch: "addItemOnMatch",
    applyButtonText: "applyButtonText",
    clearButtonProps: "clearButtonProps",
    disableValidation: "disableValidation",
    disabled: "disabled",
    editable: "editable",
    hideSelectAll: "hideSelectAll",
    inputProps: "inputProps",
    label: "label",
    limit: "limit",
    maxlength: "maxlength",
    menuProps: "menuProps",
    menuTriggerButtonProps: "menuTriggerButtonProps",
    minlength: "minlength",
    multiple: "multiple",
    name: "name",
    noClearIcon: "noClearIcon",
    notFoundText: "notFoundText",
    notSelectableValues: "notSelectableValues",
    placeholder: "placeholder",
    required: "required",
    rounded: "rounded",
    searchable: "searchable",
    selectAllText: "selectAllText",
    selectableValues: "selectableValues",
    showApplyButton: "showApplyButton",
    size: "size",
    tooltipProps: "tooltipProps",
    validity: "validity",
    value: "value",
    variant: "variant",
    visible: "visible"
  },
  outputs: {
    clearClick: "clearClick",
    clickOutside: "clickOutside",
    itemAdd: "itemAdd",
    validityChange: "validityChange",
    valueChange: "valueChange",
    visibleChange: "visibleChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcBaseDropdown_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcBaseDropdown = __decorate([ProxyCmp({
  inputs: ['addItemOnMatch', 'applyButtonText', 'clearButtonProps', 'disableValidation', 'disabled', 'editable', 'hideSelectAll', 'inputProps', 'label', 'limit', 'maxlength', 'menuProps', 'menuTriggerButtonProps', 'minlength', 'multiple', 'name', 'noClearIcon', 'notFoundText', 'notSelectableValues', 'placeholder', 'required', 'rounded', 'searchable', 'selectAllText', 'selectableValues', 'showApplyButton', 'size', 'tooltipProps', 'validity', 'value', 'variant', 'visible'],
  methods: ['validate']
})], OwcBaseDropdown);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcBaseDropdown, [{
    type: Component,
    args: [{
      selector: 'owc-base-dropdown',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['addItemOnMatch', 'applyButtonText', 'clearButtonProps', 'disableValidation', 'disabled', 'editable', 'hideSelectAll', 'inputProps', 'label', 'limit', 'maxlength', 'menuProps', 'menuTriggerButtonProps', 'minlength', 'multiple', 'name', 'noClearIcon', 'notFoundText', 'notSelectableValues', 'placeholder', 'required', 'rounded', 'searchable', 'selectAllText', 'selectableValues', 'showApplyButton', 'size', 'tooltipProps', 'validity', 'value', 'variant', 'visible'],
      outputs: ['clearClick', 'clickOutside', 'itemAdd', 'validityChange', 'valueChange', 'visibleChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcBreadcrumb = class OwcBreadcrumb {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcBreadcrumb.ɵfac = function OwcBreadcrumb_Factory(t) {
  return new (t || OwcBreadcrumb)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcBreadcrumb.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcBreadcrumb,
  selectors: [["owc-breadcrumb"]],
  inputs: {
    icon: "icon",
    iconFamily: "iconFamily"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcBreadcrumb_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcBreadcrumb = __decorate([ProxyCmp({
  inputs: ['icon', 'iconFamily']
})], OwcBreadcrumb);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcBreadcrumb, [{
    type: Component,
    args: [{
      selector: 'owc-breadcrumb',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['icon', 'iconFamily']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcBreadcrumbItem = class OwcBreadcrumbItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcBreadcrumbItem.ɵfac = function OwcBreadcrumbItem_Factory(t) {
  return new (t || OwcBreadcrumbItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcBreadcrumbItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcBreadcrumbItem,
  selectors: [["owc-breadcrumb-item"]],
  inputs: {
    disabled: "disabled",
    hyperlinkProps: "hyperlinkProps",
    icon: "icon",
    iconFamily: "iconFamily",
    parentDisabled: "parentDisabled",
    url: "url"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcBreadcrumbItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcBreadcrumbItem = __decorate([ProxyCmp({
  inputs: ['disabled', 'hyperlinkProps', 'icon', 'iconFamily', 'parentDisabled', 'url']
})], OwcBreadcrumbItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcBreadcrumbItem, [{
    type: Component,
    args: [{
      selector: 'owc-breadcrumb-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'hyperlinkProps', 'icon', 'iconFamily', 'parentDisabled', 'url']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcButton = class OwcButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcButton.ɵfac = function OwcButton_Factory(t) {
  return new (t || OwcButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcButton,
  selectors: [["owc-button"]],
  inputs: {
    disableRipple: "disableRipple",
    disabled: "disabled",
    elevated: "elevated",
    nativeButtonProps: "nativeButtonProps",
    purpose: "purpose",
    rounded: "rounded",
    size: "size",
    type: "type",
    variant: "variant"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcButton = __decorate([ProxyCmp({
  inputs: ['disableRipple', 'disabled', 'elevated', 'nativeButtonProps', 'purpose', 'rounded', 'size', 'type', 'variant']
})], OwcButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcButton, [{
    type: Component,
    args: [{
      selector: 'owc-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disableRipple', 'disabled', 'elevated', 'nativeButtonProps', 'purpose', 'rounded', 'size', 'type', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcCalendar = class OwcCalendar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['calendarBlur', 'dateSelect', 'visibleDateChange']);
  }
};
OwcCalendar.ɵfac = function OwcCalendar_Factory(t) {
  return new (t || OwcCalendar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcCalendar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcCalendar,
  selectors: [["owc-calendar"]],
  inputs: {
    disabled: "disabled",
    disabledDates: "disabledDates",
    from: "from",
    hideCurrent: "hideCurrent",
    hideHeader: "hideHeader",
    hideNextArrow: "hideNextArrow",
    hidePrevArrow: "hidePrevArrow",
    locale: "locale",
    max: "max",
    min: "min",
    to: "to",
    viewMode: "viewMode",
    visibleDate: "visibleDate",
    weekStartsOn: "weekStartsOn"
  },
  outputs: {
    calendarBlur: "calendarBlur",
    dateSelect: "dateSelect",
    visibleDateChange: "visibleDateChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcCalendar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcCalendar = __decorate([ProxyCmp({
  inputs: ['disabled', 'disabledDates', 'from', 'hideCurrent', 'hideHeader', 'hideNextArrow', 'hidePrevArrow', 'locale', 'max', 'min', 'to', 'viewMode', 'visibleDate', 'weekStartsOn']
})], OwcCalendar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcCalendar, [{
    type: Component,
    args: [{
      selector: 'owc-calendar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'disabledDates', 'from', 'hideCurrent', 'hideHeader', 'hideNextArrow', 'hidePrevArrow', 'locale', 'max', 'min', 'to', 'viewMode', 'visibleDate', 'weekStartsOn'],
      outputs: ['calendarBlur', 'dateSelect', 'visibleDateChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcCard = class OwcCard {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcCard.ɵfac = function OwcCard_Factory(t) {
  return new (t || OwcCard)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcCard.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcCard,
  selectors: [["owc-card"]],
  inputs: {
    disabled: "disabled",
    dragging: "dragging",
    elevated: "elevated",
    interactive: "interactive",
    rounded: "rounded"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcCard_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcCard = __decorate([ProxyCmp({
  inputs: ['disabled', 'dragging', 'elevated', 'interactive', 'rounded']
})], OwcCard);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcCard, [{
    type: Component,
    args: [{
      selector: 'owc-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'dragging', 'elevated', 'interactive', 'rounded']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcCheckbox = class OwcCheckbox {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['indeterminateChange', 'valueChange']);
  }
};
OwcCheckbox.ɵfac = function OwcCheckbox_Factory(t) {
  return new (t || OwcCheckbox)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcCheckbox.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcCheckbox,
  selectors: [["owc-checkbox"]],
  inputs: {
    checked: "checked",
    controlled: "controlled",
    disableRipple: "disableRipple",
    disabled: "disabled",
    hostId: "hostId",
    indeterminate: "indeterminate",
    labelPlacement: "labelPlacement",
    name: "name",
    nativeInputProps: "nativeInputProps",
    value: "value"
  },
  outputs: {
    indeterminateChange: "indeterminateChange",
    valueChange: "valueChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcCheckbox_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcCheckbox = __decorate([ProxyCmp({
  inputs: ['checked', 'controlled', 'disableRipple', 'disabled', 'hostId', 'indeterminate', 'labelPlacement', 'name', 'nativeInputProps', 'value']
})], OwcCheckbox);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcCheckbox, [{
    type: Component,
    args: [{
      selector: 'owc-checkbox',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['checked', 'controlled', 'disableRipple', 'disabled', 'hostId', 'indeterminate', 'labelPlacement', 'name', 'nativeInputProps', 'value'],
      outputs: ['indeterminateChange', 'valueChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcChip = class OwcChip {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['remove']);
  }
};
OwcChip.ɵfac = function OwcChip_Factory(t) {
  return new (t || OwcChip)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcChip.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcChip,
  selectors: [["owc-chip"]],
  inputs: {
    closeIconProps: "closeIconProps",
    disableRipple: "disableRipple",
    disabled: "disabled",
    dragging: "dragging",
    selected: "selected",
    selectedIconProps: "selectedIconProps",
    showClose: "showClose",
    size: "size",
    variant: "variant"
  },
  outputs: {
    remove: "remove"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcChip_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcChip = __decorate([ProxyCmp({
  inputs: ['closeIconProps', 'disableRipple', 'disabled', 'dragging', 'selected', 'selectedIconProps', 'showClose', 'size', 'variant']
})], OwcChip);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcChip, [{
    type: Component,
    args: [{
      selector: 'owc-chip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['closeIconProps', 'disableRipple', 'disabled', 'dragging', 'selected', 'selectedIconProps', 'showClose', 'size', 'variant'],
      outputs: ['remove']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcComponentFooter = class OwcComponentFooter {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcComponentFooter.ɵfac = function OwcComponentFooter_Factory(t) {
  return new (t || OwcComponentFooter)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcComponentFooter.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcComponentFooter,
  selectors: [["owc-component-footer"]],
  inputs: {
    separator: "separator"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcComponentFooter_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcComponentFooter = __decorate([ProxyCmp({
  inputs: ['separator']
})], OwcComponentFooter);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcComponentFooter, [{
    type: Component,
    args: [{
      selector: 'owc-component-footer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['separator']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcComponentHeader = class OwcComponentHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcComponentHeader.ɵfac = function OwcComponentHeader_Factory(t) {
  return new (t || OwcComponentHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcComponentHeader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcComponentHeader,
  selectors: [["owc-component-header"]],
  inputs: {
    separator: "separator",
    status: "status"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcComponentHeader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcComponentHeader = __decorate([ProxyCmp({
  inputs: ['separator', 'status']
})], OwcComponentHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcComponentHeader, [{
    type: Component,
    args: [{
      selector: 'owc-component-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['separator', 'status']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcContactInfo = class OwcContactInfo {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcContactInfo.ɵfac = function OwcContactInfo_Factory(t) {
  return new (t || OwcContactInfo)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcContactInfo.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcContactInfo,
  selectors: [["owc-contact-info"]],
  inputs: {
    type: "type"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcContactInfo_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcContactInfo = __decorate([ProxyCmp({
  inputs: ['type']
})], OwcContactInfo);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcContactInfo, [{
    type: Component,
    args: [{
      selector: 'owc-contact-info',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['type']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcCookieAcceptance = class OwcCookieAcceptance {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['acceptSelect']);
  }
};
OwcCookieAcceptance.ɵfac = function OwcCookieAcceptance_Factory(t) {
  return new (t || OwcCookieAcceptance)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcCookieAcceptance.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcCookieAcceptance,
  selectors: [["owc-cookie-acceptance"]],
  inputs: {
    acceptAllButtonProps: "acceptAllButtonProps",
    acceptAllText: "acceptAllText",
    acceptSelectionButtonProps: "acceptSelectionButtonProps",
    acceptSelectionText: "acceptSelectionText",
    barVisibility: "barVisibility",
    cancelButtonProps: "cancelButtonProps",
    cancelText: "cancelText",
    editPreferencesButtonProps: "editPreferencesButtonProps",
    editPreferencesText: "editPreferencesText",
    settingsModalTitle: "settingsModalTitle"
  },
  outputs: {
    acceptSelect: "acceptSelect"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcCookieAcceptance_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcCookieAcceptance = __decorate([ProxyCmp({
  inputs: ['acceptAllButtonProps', 'acceptAllText', 'acceptSelectionButtonProps', 'acceptSelectionText', 'barVisibility', 'cancelButtonProps', 'cancelText', 'editPreferencesButtonProps', 'editPreferencesText', 'settingsModalTitle']
})], OwcCookieAcceptance);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcCookieAcceptance, [{
    type: Component,
    args: [{
      selector: 'owc-cookie-acceptance',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['acceptAllButtonProps', 'acceptAllText', 'acceptSelectionButtonProps', 'acceptSelectionText', 'barVisibility', 'cancelButtonProps', 'cancelText', 'editPreferencesButtonProps', 'editPreferencesText', 'settingsModalTitle'],
      outputs: ['acceptSelect']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcCookieAcceptanceOption = class OwcCookieAcceptanceOption {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcCookieAcceptanceOption.ɵfac = function OwcCookieAcceptanceOption_Factory(t) {
  return new (t || OwcCookieAcceptanceOption)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcCookieAcceptanceOption.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcCookieAcceptanceOption,
  selectors: [["owc-cookie-acceptance-option"]],
  inputs: {
    accepted: "accepted",
    disabled: "disabled",
    name: "name"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcCookieAcceptanceOption_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcCookieAcceptanceOption = __decorate([ProxyCmp({
  inputs: ['accepted', 'disabled', 'name']
})], OwcCookieAcceptanceOption);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcCookieAcceptanceOption, [{
    type: Component,
    args: [{
      selector: 'owc-cookie-acceptance-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['accepted', 'disabled', 'name']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcDatepicker = class OwcDatepicker {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['clearClick', 'clickOutside', 'inputBlur', 'inputKeyDown', 'inputValueChange', 'panelClose', 'panelOpen', 'validityChange', 'valueChange']);
  }
};
OwcDatepicker.ɵfac = function OwcDatepicker_Factory(t) {
  return new (t || OwcDatepicker)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcDatepicker.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcDatepicker,
  selectors: [["owc-datepicker"]],
  inputs: {
    autoClose: "autoClose",
    dayText: "dayText",
    disableValidation: "disableValidation",
    disabled: "disabled",
    disabledDates: "disabledDates",
    format: "format",
    inputProps: "inputProps",
    label: "label",
    locale: "locale",
    maxDate: "maxDate",
    minDate: "minDate",
    monthText: "monthText",
    name: "name",
    noClearIcon: "noClearIcon",
    placeholder: "placeholder",
    popoverProps: "popoverProps",
    quick: "quick",
    required: "required",
    selectRangeText: "selectRangeText",
    size: "size",
    type: "type",
    validity: "validity",
    value: "value",
    variant: "variant",
    weekStartsOn: "weekStartsOn",
    yearText: "yearText"
  },
  outputs: {
    clearClick: "clearClick",
    clickOutside: "clickOutside",
    inputBlur: "inputBlur",
    inputKeyDown: "inputKeyDown",
    inputValueChange: "inputValueChange",
    panelClose: "panelClose",
    panelOpen: "panelOpen",
    validityChange: "validityChange",
    valueChange: "valueChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcDatepicker_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcDatepicker = __decorate([ProxyCmp({
  inputs: ['autoClose', 'dayText', 'disableValidation', 'disabled', 'disabledDates', 'format', 'inputProps', 'label', 'locale', 'maxDate', 'minDate', 'monthText', 'name', 'noClearIcon', 'placeholder', 'popoverProps', 'quick', 'required', 'selectRangeText', 'size', 'type', 'validity', 'value', 'variant', 'weekStartsOn', 'yearText'],
  methods: ['setCalendarVisible', 'validate']
})], OwcDatepicker);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcDatepicker, [{
    type: Component,
    args: [{
      selector: 'owc-datepicker',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['autoClose', 'dayText', 'disableValidation', 'disabled', 'disabledDates', 'format', 'inputProps', 'label', 'locale', 'maxDate', 'minDate', 'monthText', 'name', 'noClearIcon', 'placeholder', 'popoverProps', 'quick', 'required', 'selectRangeText', 'size', 'type', 'validity', 'value', 'variant', 'weekStartsOn', 'yearText'],
      outputs: ['clearClick', 'clickOutside', 'inputBlur', 'inputKeyDown', 'inputValueChange', 'panelClose', 'panelOpen', 'validityChange', 'valueChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcDivider = class OwcDivider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcDivider.ɵfac = function OwcDivider_Factory(t) {
  return new (t || OwcDivider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcDivider.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcDivider,
  selectors: [["owc-divider"]],
  inputs: {
    captionPosition: "captionPosition",
    direction: "direction",
    type: "type"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcDivider_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcDivider = __decorate([ProxyCmp({
  inputs: ['captionPosition', 'direction', 'type']
})], OwcDivider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcDivider, [{
    type: Component,
    args: [{
      selector: 'owc-divider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['captionPosition', 'direction', 'type']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcDot = class OwcDot {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['selectedChange']);
  }
};
OwcDot.ɵfac = function OwcDot_Factory(t) {
  return new (t || OwcDot)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcDot.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcDot,
  selectors: [["owc-dot"]],
  inputs: {
    disableRipple: "disableRipple",
    disabled: "disabled",
    parentDisabled: "parentDisabled",
    selected: "selected"
  },
  outputs: {
    selectedChange: "selectedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcDot_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcDot = __decorate([ProxyCmp({
  inputs: ['disableRipple', 'disabled', 'parentDisabled', 'selected']
})], OwcDot);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcDot, [{
    type: Component,
    args: [{
      selector: 'owc-dot',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disableRipple', 'disabled', 'parentDisabled', 'selected'],
      outputs: ['selectedChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcDots = class OwcDots {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['selectedChange']);
  }
};
OwcDots.ɵfac = function OwcDots_Factory(t) {
  return new (t || OwcDots)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcDots.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcDots,
  selectors: [["owc-dots"]],
  inputs: {
    disabled: "disabled",
    selected: "selected"
  },
  outputs: {
    selectedChange: "selectedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcDots_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcDots = __decorate([ProxyCmp({
  inputs: ['disabled', 'selected']
})], OwcDots);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcDots, [{
    type: Component,
    args: [{
      selector: 'owc-dots',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'selected'],
      outputs: ['selectedChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcDrawer = class OwcDrawer {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['visibleChange']);
  }
};
OwcDrawer.ɵfac = function OwcDrawer_Factory(t) {
  return new (t || OwcDrawer)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcDrawer.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcDrawer,
  selectors: [["owc-drawer"]],
  inputs: {
    backdropProps: "backdropProps",
    hideBackdrop: "hideBackdrop",
    mode: "mode",
    placement: "placement",
    visible: "visible"
  },
  outputs: {
    visibleChange: "visibleChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcDrawer_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcDrawer = __decorate([ProxyCmp({
  inputs: ['backdropProps', 'hideBackdrop', 'mode', 'placement', 'visible']
})], OwcDrawer);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcDrawer, [{
    type: Component,
    args: [{
      selector: 'owc-drawer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['backdropProps', 'hideBackdrop', 'mode', 'placement', 'visible'],
      outputs: ['visibleChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcEditableDropdown = class OwcEditableDropdown {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['clearClick', 'clickOutside', 'itemAdd', 'validityChange', 'valueChange', 'visibleChange']);
  }
};
OwcEditableDropdown.ɵfac = function OwcEditableDropdown_Factory(t) {
  return new (t || OwcEditableDropdown)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcEditableDropdown.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcEditableDropdown,
  selectors: [["owc-editable-dropdown"]],
  inputs: {
    addItemOnMatch: "addItemOnMatch",
    clearButtonProps: "clearButtonProps",
    disableValidation: "disableValidation",
    disabled: "disabled",
    inputProps: "inputProps",
    label: "label",
    limit: "limit",
    menuProps: "menuProps",
    menuTriggerButtonProps: "menuTriggerButtonProps",
    name: "name",
    noClearIcon: "noClearIcon",
    notFoundText: "notFoundText",
    notSelectableValues: "notSelectableValues",
    placeholder: "placeholder",
    required: "required",
    rounded: "rounded",
    selectableValues: "selectableValues",
    size: "size",
    tooltipProps: "tooltipProps",
    validity: "validity",
    value: "value",
    variant: "variant",
    visible: "visible"
  },
  outputs: {
    clearClick: "clearClick",
    clickOutside: "clickOutside",
    itemAdd: "itemAdd",
    validityChange: "validityChange",
    valueChange: "valueChange",
    visibleChange: "visibleChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcEditableDropdown_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcEditableDropdown = __decorate([ProxyCmp({
  inputs: ['addItemOnMatch', 'clearButtonProps', 'disableValidation', 'disabled', 'inputProps', 'label', 'limit', 'menuProps', 'menuTriggerButtonProps', 'name', 'noClearIcon', 'notFoundText', 'notSelectableValues', 'placeholder', 'required', 'rounded', 'selectableValues', 'size', 'tooltipProps', 'validity', 'value', 'variant', 'visible'],
  methods: ['validate']
})], OwcEditableDropdown);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcEditableDropdown, [{
    type: Component,
    args: [{
      selector: 'owc-editable-dropdown',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['addItemOnMatch', 'clearButtonProps', 'disableValidation', 'disabled', 'inputProps', 'label', 'limit', 'menuProps', 'menuTriggerButtonProps', 'name', 'noClearIcon', 'notFoundText', 'notSelectableValues', 'placeholder', 'required', 'rounded', 'selectableValues', 'size', 'tooltipProps', 'validity', 'value', 'variant', 'visible'],
      outputs: ['clearClick', 'clickOutside', 'itemAdd', 'validityChange', 'valueChange', 'visibleChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcExpandable = class OwcExpandable {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['expandedChange']);
  }
};
OwcExpandable.ɵfac = function OwcExpandable_Factory(t) {
  return new (t || OwcExpandable)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcExpandable.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcExpandable,
  selectors: [["owc-expandable"]],
  inputs: {
    controlPlacement: "controlPlacement",
    disabled: "disabled",
    expanded: "expanded",
    roundedControl: "roundedControl",
    variant: "variant"
  },
  outputs: {
    expandedChange: "expandedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcExpandable_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcExpandable = __decorate([ProxyCmp({
  inputs: ['controlPlacement', 'disabled', 'expanded', 'roundedControl', 'variant']
})], OwcExpandable);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcExpandable, [{
    type: Component,
    args: [{
      selector: 'owc-expandable',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['controlPlacement', 'disabled', 'expanded', 'roundedControl', 'variant'],
      outputs: ['expandedChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcExpandableGroup = class OwcExpandableGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['expandedElementsChanged']);
  }
};
OwcExpandableGroup.ɵfac = function OwcExpandableGroup_Factory(t) {
  return new (t || OwcExpandableGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcExpandableGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcExpandableGroup,
  selectors: [["owc-expandable-group"]],
  inputs: {
    multiple: "multiple"
  },
  outputs: {
    expandedElementsChanged: "expandedElementsChanged"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcExpandableGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcExpandableGroup = __decorate([ProxyCmp({
  inputs: ['multiple'],
  methods: ['setAllState']
})], OwcExpandableGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcExpandableGroup, [{
    type: Component,
    args: [{
      selector: 'owc-expandable-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['multiple'],
      outputs: ['expandedElementsChanged']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcFooter = class OwcFooter {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcFooter.ɵfac = function OwcFooter_Factory(t) {
  return new (t || OwcFooter)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcFooter.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcFooter,
  selectors: [["owc-footer"]],
  inputs: {
    fixBottom: "fixBottom",
    variant: "variant"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcFooter_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcFooter = __decorate([ProxyCmp({
  inputs: ['fixBottom', 'variant']
})], OwcFooter);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcFooter, [{
    type: Component,
    args: [{
      selector: 'owc-footer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['fixBottom', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcGrid = class OwcGrid {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcGrid.ɵfac = function OwcGrid_Factory(t) {
  return new (t || OwcGrid)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcGrid.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcGrid,
  selectors: [["owc-grid"]],
  inputs: {
    columns: "columns",
    horizontalSpacing: "horizontalSpacing",
    spacing: "spacing",
    verticalSpacing: "verticalSpacing"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcGrid_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcGrid = __decorate([ProxyCmp({
  inputs: ['columns', 'horizontalSpacing', 'spacing', 'verticalSpacing']
})], OwcGrid);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcGrid, [{
    type: Component,
    args: [{
      selector: 'owc-grid',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['columns', 'horizontalSpacing', 'spacing', 'verticalSpacing']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcGridItem = class OwcGridItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcGridItem.ɵfac = function OwcGridItem_Factory(t) {
  return new (t || OwcGridItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcGridItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcGridItem,
  selectors: [["owc-grid-item"]],
  inputs: {
    horizontalPlacement: "horizontalPlacement",
    lg: "lg",
    md: "md",
    sm: "sm",
    verticalPlacement: "verticalPlacement",
    xl: "xl",
    xs: "xs"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcGridItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcGridItem = __decorate([ProxyCmp({
  inputs: ['horizontalPlacement', 'lg', 'md', 'sm', 'verticalPlacement', 'xl', 'xs']
})], OwcGridItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcGridItem, [{
    type: Component,
    args: [{
      selector: 'owc-grid-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['horizontalPlacement', 'lg', 'md', 'sm', 'verticalPlacement', 'xl', 'xs']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcHeader = class OwcHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcHeader.ɵfac = function OwcHeader_Factory(t) {
  return new (t || OwcHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcHeader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcHeader,
  selectors: [["owc-header"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcHeader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcHeader = __decorate([ProxyCmp({
  inputs: []
})], OwcHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcHeader, [{
    type: Component,
    args: [{
      selector: 'owc-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcHeaderRow = class OwcHeaderRow {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcHeaderRow.ɵfac = function OwcHeaderRow_Factory(t) {
  return new (t || OwcHeaderRow)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcHeaderRow.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcHeaderRow,
  selectors: [["owc-header-row"]],
  inputs: {
    elevated: "elevated",
    extended: "extended",
    separator: "separator",
    variant: "variant"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcHeaderRow_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcHeaderRow = __decorate([ProxyCmp({
  inputs: ['elevated', 'extended', 'separator', 'variant']
})], OwcHeaderRow);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcHeaderRow, [{
    type: Component,
    args: [{
      selector: 'owc-header-row',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['elevated', 'extended', 'separator', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcHyperlink = class OwcHyperlink {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcHyperlink.ɵfac = function OwcHyperlink_Factory(t) {
  return new (t || OwcHyperlink)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcHyperlink.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcHyperlink,
  selectors: [["owc-hyperlink"]],
  inputs: {
    anchorProps: "anchorProps",
    disabled: "disabled",
    href: "href",
    purpose: "purpose",
    rel: "rel",
    size: "size",
    target: "target"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcHyperlink_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcHyperlink = __decorate([ProxyCmp({
  inputs: ['anchorProps', 'disabled', 'href', 'purpose', 'rel', 'size', 'target']
})], OwcHyperlink);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcHyperlink, [{
    type: Component,
    args: [{
      selector: 'owc-hyperlink',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['anchorProps', 'disabled', 'href', 'purpose', 'rel', 'size', 'target']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcIcon = class OwcIcon {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcIcon.ɵfac = function OwcIcon_Factory(t) {
  return new (t || OwcIcon)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcIcon.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcIcon,
  selectors: [["owc-icon"]],
  inputs: {
    alt: "alt",
    family: "family",
    name: "name",
    status: "status"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcIcon_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcIcon = __decorate([ProxyCmp({
  inputs: ['alt', 'family', 'name', 'status']
})], OwcIcon);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcIcon, [{
    type: Component,
    args: [{
      selector: 'owc-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['alt', 'family', 'name', 'status']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcIconButton = class OwcIconButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcIconButton.ɵfac = function OwcIconButton_Factory(t) {
  return new (t || OwcIconButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcIconButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcIconButton,
  selectors: [["owc-icon-button"]],
  inputs: {
    active: "active",
    alternate: "alternate",
    disableRipple: "disableRipple",
    disabled: "disabled",
    elevated: "elevated",
    family: "family",
    icon: "icon",
    nativeButtonProps: "nativeButtonProps",
    size: "size",
    variant: "variant"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcIconButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcIconButton = __decorate([ProxyCmp({
  inputs: ['active', 'alternate', 'disableRipple', 'disabled', 'elevated', 'family', 'icon', 'nativeButtonProps', 'size', 'variant']
})], OwcIconButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcIconButton, [{
    type: Component,
    args: [{
      selector: 'owc-icon-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['active', 'alternate', 'disableRipple', 'disabled', 'elevated', 'family', 'icon', 'nativeButtonProps', 'size', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcIconProvider = class OwcIconProvider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcIconProvider.ɵfac = function OwcIconProvider_Factory(t) {
  return new (t || OwcIconProvider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcIconProvider.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcIconProvider,
  selectors: [["owc-icon-provider"]],
  inputs: {
    base: "base",
    family: "family"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcIconProvider_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcIconProvider = __decorate([ProxyCmp({
  inputs: ['base', 'family']
})], OwcIconProvider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcIconProvider, [{
    type: Component,
    args: [{
      selector: 'owc-icon-provider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['base', 'family']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcIndicatorBadge = class OwcIndicatorBadge {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcIndicatorBadge.ɵfac = function OwcIndicatorBadge_Factory(t) {
  return new (t || OwcIndicatorBadge)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcIndicatorBadge.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcIndicatorBadge,
  selectors: [["owc-indicator-badge"]],
  inputs: {
    badgeProps: "badgeProps",
    content: "content",
    disabled: "disabled",
    horizontalPlacement: "horizontalPlacement",
    overlap: "overlap",
    size: "size",
    status: "status",
    verticalPlacement: "verticalPlacement"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcIndicatorBadge_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcIndicatorBadge = __decorate([ProxyCmp({
  inputs: ['badgeProps', 'content', 'disabled', 'horizontalPlacement', 'overlap', 'size', 'status', 'verticalPlacement']
})], OwcIndicatorBadge);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcIndicatorBadge, [{
    type: Component,
    args: [{
      selector: 'owc-indicator-badge',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['badgeProps', 'content', 'disabled', 'horizontalPlacement', 'overlap', 'size', 'status', 'verticalPlacement']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcInput = class OwcInput {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['clearClick', 'clickOutside', 'edit', 'inputKeyDown', 'validityChange', 'valueChange']);
  }
};
OwcInput.ɵfac = function OwcInput_Factory(t) {
  return new (t || OwcInput)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcInput.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcInput,
  selectors: [["owc-input"]],
  inputs: {
    autoFocus: "autoFocus",
    clearIconProps: "clearIconProps",
    cols: "cols",
    disableFocusRedirect: "disableFocusRedirect",
    disableValidation: "disableValidation",
    disabled: "disabled",
    editIconProps: "editIconProps",
    focusedView: "focusedView",
    hostId: "hostId",
    hostRole: "hostRole",
    label: "label",
    locale: "locale",
    max: "max",
    maxlength: "maxlength",
    min: "min",
    minlength: "minlength",
    name: "name",
    nativeInputProps: "nativeInputProps",
    noClearIcon: "noClearIcon",
    notSelectableValues: "notSelectableValues",
    pattern: "pattern",
    placeholder: "placeholder",
    protection: "protection",
    required: "required",
    resizable: "resizable",
    rounded: "rounded",
    rows: "rows",
    selectableValues: "selectableValues",
    selectionEnd: "selectionEnd",
    selectionStart: "selectionStart",
    showCharCount: "showCharCount",
    size: "size",
    type: "type",
    validity: "validity",
    value: "value",
    variant: "variant"
  },
  outputs: {
    clearClick: "clearClick",
    clickOutside: "clickOutside",
    edit: "edit",
    inputKeyDown: "inputKeyDown",
    validityChange: "validityChange",
    valueChange: "valueChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcInput_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcInput = __decorate([ProxyCmp({
  inputs: ['autoFocus', 'clearIconProps', 'cols', 'disableFocusRedirect', 'disableValidation', 'disabled', 'editIconProps', 'focusedView', 'hostId', 'hostRole', 'label', 'locale', 'max', 'maxlength', 'min', 'minlength', 'name', 'nativeInputProps', 'noClearIcon', 'notSelectableValues', 'pattern', 'placeholder', 'protection', 'required', 'resizable', 'rounded', 'rows', 'selectableValues', 'selectionEnd', 'selectionStart', 'showCharCount', 'size', 'type', 'validity', 'value', 'variant'],
  methods: ['validate']
})], OwcInput);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcInput, [{
    type: Component,
    args: [{
      selector: 'owc-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['autoFocus', 'clearIconProps', 'cols', 'disableFocusRedirect', 'disableValidation', 'disabled', 'editIconProps', 'focusedView', 'hostId', 'hostRole', 'label', 'locale', 'max', 'maxlength', 'min', 'minlength', 'name', 'nativeInputProps', 'noClearIcon', 'notSelectableValues', 'pattern', 'placeholder', 'protection', 'required', 'resizable', 'rounded', 'rows', 'selectableValues', 'selectionEnd', 'selectionStart', 'showCharCount', 'size', 'type', 'validity', 'value', 'variant'],
      outputs: ['clearClick', 'clickOutside', 'edit', 'inputKeyDown', 'validityChange', 'valueChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcList = class OwcList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['selectedChange']);
  }
};
OwcList.ɵfac = function OwcList_Factory(t) {
  return new (t || OwcList)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcList.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcList,
  selectors: [["owc-list"]],
  inputs: {
    hostId: "hostId",
    hostRole: "hostRole",
    multiple: "multiple",
    noBorder: "noBorder",
    notFocusable: "notFocusable",
    position: "position",
    selected: "selected",
    showSelectorControl: "showSelectorControl",
    size: "size"
  },
  outputs: {
    selectedChange: "selectedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcList_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcList = __decorate([ProxyCmp({
  inputs: ['hostId', 'hostRole', 'multiple', 'noBorder', 'notFocusable', 'position', 'selected', 'showSelectorControl', 'size']
})], OwcList);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcList, [{
    type: Component,
    args: [{
      selector: 'owc-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['hostId', 'hostRole', 'multiple', 'noBorder', 'notFocusable', 'position', 'selected', 'showSelectorControl', 'size'],
      outputs: ['selectedChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcListItem = class OwcListItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['expandedChange']);
  }
};
OwcListItem.ɵfac = function OwcListItem_Factory(t) {
  return new (t || OwcListItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcListItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcListItem,
  selectors: [["owc-list-item"]],
  inputs: {
    checkboxState: "checkboxState",
    disableRipple: "disableRipple",
    disabled: "disabled",
    expanded: "expanded",
    expanderIconProps: "expanderIconProps",
    hostHidden: "hostHidden",
    hostId: "hostId",
    hostRole: "hostRole",
    leaf: "leaf",
    managedCheckboxState: "managedCheckboxState",
    multiple: "multiple",
    noBorder: "noBorder",
    noExpandIcon: "noExpandIcon",
    notFocusable: "notFocusable",
    notSelectable: "notSelectable",
    parentDisabled: "parentDisabled",
    position: "position",
    searchKey: "searchKey",
    selected: "selected",
    showSelectorControl: "showSelectorControl",
    size: "size",
    tooltipPlacement: "tooltipPlacement",
    value: "value"
  },
  outputs: {
    expandedChange: "expandedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcListItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcListItem = __decorate([ProxyCmp({
  inputs: ['checkboxState', 'disableRipple', 'disabled', 'expanded', 'expanderIconProps', 'hostHidden', 'hostId', 'hostRole', 'leaf', 'managedCheckboxState', 'multiple', 'noBorder', 'noExpandIcon', 'notFocusable', 'notSelectable', 'parentDisabled', 'position', 'searchKey', 'selected', 'showSelectorControl', 'size', 'tooltipPlacement', 'value'],
  methods: ['getIndentationLevel', 'getTextContent', 'updateInternalStates']
})], OwcListItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcListItem, [{
    type: Component,
    args: [{
      selector: 'owc-list-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['checkboxState', 'disableRipple', 'disabled', 'expanded', 'expanderIconProps', 'hostHidden', 'hostId', 'hostRole', 'leaf', 'managedCheckboxState', 'multiple', 'noBorder', 'noExpandIcon', 'notFocusable', 'notSelectable', 'parentDisabled', 'position', 'searchKey', 'selected', 'showSelectorControl', 'size', 'tooltipPlacement', 'value'],
      outputs: ['expandedChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcLogo = class OwcLogo {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcLogo.ɵfac = function OwcLogo_Factory(t) {
  return new (t || OwcLogo)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcLogo.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcLogo,
  selectors: [["owc-logo"]],
  inputs: {
    name: "name",
    size: "size"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcLogo_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcLogo = __decorate([ProxyCmp({
  inputs: ['name', 'size']
})], OwcLogo);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcLogo, [{
    type: Component,
    args: [{
      selector: 'owc-logo',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['name', 'size']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcMenu = class OwcMenu {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['clickOutside', 'visibleChange']);
  }
};
OwcMenu.ɵfac = function OwcMenu_Factory(t) {
  return new (t || OwcMenu)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcMenu.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcMenu,
  selectors: [["owc-menu"]],
  inputs: {
    anchor: "anchor",
    autoCloseOnSelection: "autoCloseOnSelection",
    cardProps: "cardProps",
    hostId: "hostId",
    limit: "limit",
    listProps: "listProps",
    notFocusable: "notFocusable",
    offset: "offset",
    position: "position",
    preventOverflow: "preventOverflow",
    preventVisibilityChangeOnAnchorBlur: "preventVisibilityChangeOnAnchorBlur",
    strategy: "strategy",
    trigger: "trigger",
    visible: "visible"
  },
  outputs: {
    clickOutside: "clickOutside",
    visibleChange: "visibleChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcMenu_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcMenu = __decorate([ProxyCmp({
  inputs: ['anchor', 'autoCloseOnSelection', 'cardProps', 'hostId', 'limit', 'listProps', 'notFocusable', 'offset', 'position', 'preventOverflow', 'preventVisibilityChangeOnAnchorBlur', 'strategy', 'trigger', 'visible']
})], OwcMenu);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcMenu, [{
    type: Component,
    args: [{
      selector: 'owc-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['anchor', 'autoCloseOnSelection', 'cardProps', 'hostId', 'limit', 'listProps', 'notFocusable', 'offset', 'position', 'preventOverflow', 'preventVisibilityChangeOnAnchorBlur', 'strategy', 'trigger', 'visible'],
      outputs: ['clickOutside', 'visibleChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcModalDialog = class OwcModalDialog {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['visibleChange']);
  }
};
OwcModalDialog.ɵfac = function OwcModalDialog_Factory(t) {
  return new (t || OwcModalDialog)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcModalDialog.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcModalDialog,
  selectors: [["owc-modal-dialog"]],
  inputs: {
    backdropProps: "backdropProps",
    cardProps: "cardProps",
    disableBackdropClick: "disableBackdropClick",
    disableEscapeKeydown: "disableEscapeKeydown",
    disableKeydownManager: "disableKeydownManager",
    disableScrollLock: "disableScrollLock",
    hideBackdrop: "hideBackdrop",
    size: "size",
    visible: "visible"
  },
  outputs: {
    visibleChange: "visibleChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcModalDialog_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcModalDialog = __decorate([ProxyCmp({
  inputs: ['backdropProps', 'cardProps', 'disableBackdropClick', 'disableEscapeKeydown', 'disableKeydownManager', 'disableScrollLock', 'hideBackdrop', 'size', 'visible']
})], OwcModalDialog);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcModalDialog, [{
    type: Component,
    args: [{
      selector: 'owc-modal-dialog',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['backdropProps', 'cardProps', 'disableBackdropClick', 'disableEscapeKeydown', 'disableKeydownManager', 'disableScrollLock', 'hideBackdrop', 'size', 'visible'],
      outputs: ['visibleChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcMonthCalendar = class OwcMonthCalendar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dateSelect', 'monthCalendarFocusout', 'visibleDateChange']);
  }
};
OwcMonthCalendar.ɵfac = function OwcMonthCalendar_Factory(t) {
  return new (t || OwcMonthCalendar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcMonthCalendar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcMonthCalendar,
  selectors: [["owc-month-calendar"]],
  inputs: {
    disabled: "disabled",
    disabledDates: "disabledDates",
    from: "from",
    hideCurrent: "hideCurrent",
    locale: "locale",
    max: "max",
    min: "min",
    to: "to",
    visibleDate: "visibleDate",
    weekStartsOn: "weekStartsOn"
  },
  outputs: {
    dateSelect: "dateSelect",
    monthCalendarFocusout: "monthCalendarFocusout",
    visibleDateChange: "visibleDateChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcMonthCalendar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcMonthCalendar = __decorate([ProxyCmp({
  inputs: ['disabled', 'disabledDates', 'from', 'hideCurrent', 'locale', 'max', 'min', 'to', 'visibleDate', 'weekStartsOn']
})], OwcMonthCalendar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcMonthCalendar, [{
    type: Component,
    args: [{
      selector: 'owc-month-calendar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'disabledDates', 'from', 'hideCurrent', 'locale', 'max', 'min', 'to', 'visibleDate', 'weekStartsOn'],
      outputs: ['dateSelect', 'monthCalendarFocusout', 'visibleDateChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcNotificationDrawer = class OwcNotificationDrawer {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcNotificationDrawer.ɵfac = function OwcNotificationDrawer_Factory(t) {
  return new (t || OwcNotificationDrawer)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcNotificationDrawer.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcNotificationDrawer,
  selectors: [["owc-notification-drawer"]],
  inputs: {
    limit: "limit"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcNotificationDrawer_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcNotificationDrawer = __decorate([ProxyCmp({
  inputs: ['limit'],
  methods: ['addChild']
})], OwcNotificationDrawer);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcNotificationDrawer, [{
    type: Component,
    args: [{
      selector: 'owc-notification-drawer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['limit']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcPagination = class OwcPagination {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['pageChange']);
  }
};
OwcPagination.ɵfac = function OwcPagination_Factory(t) {
  return new (t || OwcPagination)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcPagination.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcPagination,
  selectors: [["owc-pagination"]],
  inputs: {
    firstPageButtonProps: "firstPageButtonProps",
    hideFirstPageButton: "hideFirstPageButton",
    hideLastPageButton: "hideLastPageButton",
    hidePageSize: "hidePageSize",
    lastPageButtonProps: "lastPageButtonProps",
    nextPageButtonProps: "nextPageButtonProps",
    page: "page",
    pageSize: "pageSize",
    pageSizeOptions: "pageSizeOptions",
    previousPageButtonProps: "previousPageButtonProps",
    selectDropdownProps: "selectDropdownProps",
    total: "total"
  },
  outputs: {
    pageChange: "pageChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcPagination_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcPagination = __decorate([ProxyCmp({
  inputs: ['firstPageButtonProps', 'hideFirstPageButton', 'hideLastPageButton', 'hidePageSize', 'lastPageButtonProps', 'nextPageButtonProps', 'page', 'pageSize', 'pageSizeOptions', 'previousPageButtonProps', 'selectDropdownProps', 'total']
})], OwcPagination);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcPagination, [{
    type: Component,
    args: [{
      selector: 'owc-pagination',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['firstPageButtonProps', 'hideFirstPageButton', 'hideLastPageButton', 'hidePageSize', 'lastPageButtonProps', 'nextPageButtonProps', 'page', 'pageSize', 'pageSizeOptions', 'previousPageButtonProps', 'selectDropdownProps', 'total'],
      outputs: ['pageChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcPopover = class OwcPopover {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['clickOutside', 'visibleChange']);
  }
};
OwcPopover.ɵfac = function OwcPopover_Factory(t) {
  return new (t || OwcPopover)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcPopover.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcPopover,
  selectors: [["owc-popover"]],
  inputs: {
    anchor: "anchor",
    disableAccessibility: "disableAccessibility",
    hostId: "hostId",
    offset: "offset",
    position: "position",
    preventOverflow: "preventOverflow",
    strategy: "strategy",
    trigger: "trigger",
    visible: "visible"
  },
  outputs: {
    clickOutside: "clickOutside",
    visibleChange: "visibleChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcPopover_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcPopover = __decorate([ProxyCmp({
  inputs: ['anchor', 'disableAccessibility', 'hostId', 'offset', 'position', 'preventOverflow', 'strategy', 'trigger', 'visible']
})], OwcPopover);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcPopover, [{
    type: Component,
    args: [{
      selector: 'owc-popover',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['anchor', 'disableAccessibility', 'hostId', 'offset', 'position', 'preventOverflow', 'strategy', 'trigger', 'visible'],
      outputs: ['clickOutside', 'visibleChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcProgressBar = class OwcProgressBar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcProgressBar.ɵfac = function OwcProgressBar_Factory(t) {
  return new (t || OwcProgressBar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcProgressBar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcProgressBar,
  selectors: [["owc-progress-bar"]],
  inputs: {
    caption: "caption",
    hostId: "hostId",
    mode: "mode",
    status: "status",
    value: "value",
    variant: "variant"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcProgressBar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcProgressBar = __decorate([ProxyCmp({
  inputs: ['caption', 'hostId', 'mode', 'status', 'value', 'variant']
})], OwcProgressBar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcProgressBar, [{
    type: Component,
    args: [{
      selector: 'owc-progress-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['caption', 'hostId', 'mode', 'status', 'value', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcProgressSpinner = class OwcProgressSpinner {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcProgressSpinner.ɵfac = function OwcProgressSpinner_Factory(t) {
  return new (t || OwcProgressSpinner)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcProgressSpinner.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcProgressSpinner,
  selectors: [["owc-progress-spinner"]],
  inputs: {
    mode: "mode",
    size: "size",
    value: "value",
    variant: "variant"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcProgressSpinner_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcProgressSpinner = __decorate([ProxyCmp({
  inputs: ['mode', 'size', 'value', 'variant']
})], OwcProgressSpinner);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcProgressSpinner, [{
    type: Component,
    args: [{
      selector: 'owc-progress-spinner',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['mode', 'size', 'value', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcRadio = class OwcRadio {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
};
OwcRadio.ɵfac = function OwcRadio_Factory(t) {
  return new (t || OwcRadio)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcRadio.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcRadio,
  selectors: [["owc-radio"]],
  inputs: {
    checked: "checked",
    controlled: "controlled",
    disableRipple: "disableRipple",
    disabled: "disabled",
    groupDisabled: "groupDisabled",
    hostId: "hostId",
    labelPlacement: "labelPlacement",
    name: "name",
    nativeInputProps: "nativeInputProps",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcRadio_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcRadio = __decorate([ProxyCmp({
  inputs: ['checked', 'controlled', 'disableRipple', 'disabled', 'groupDisabled', 'hostId', 'labelPlacement', 'name', 'nativeInputProps', 'value']
})], OwcRadio);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcRadio, [{
    type: Component,
    args: [{
      selector: 'owc-radio',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['checked', 'controlled', 'disableRipple', 'disabled', 'groupDisabled', 'hostId', 'labelPlacement', 'name', 'nativeInputProps', 'value'],
      outputs: ['valueChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcRadioGroup = class OwcRadioGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
};
OwcRadioGroup.ɵfac = function OwcRadioGroup_Factory(t) {
  return new (t || OwcRadioGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcRadioGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcRadioGroup,
  selectors: [["owc-radio-group"]],
  inputs: {
    direction: "direction",
    disabled: "disabled",
    name: "name",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcRadioGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcRadioGroup = __decorate([ProxyCmp({
  inputs: ['direction', 'disabled', 'name', 'value']
})], OwcRadioGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcRadioGroup, [{
    type: Component,
    args: [{
      selector: 'owc-radio-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['direction', 'disabled', 'name', 'value'],
      outputs: ['valueChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcSelectDropdown = class OwcSelectDropdown {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['clearClick', 'clickOutside', 'validityChange', 'valueChange', 'visibleChange']);
  }
};
OwcSelectDropdown.ɵfac = function OwcSelectDropdown_Factory(t) {
  return new (t || OwcSelectDropdown)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcSelectDropdown.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcSelectDropdown,
  selectors: [["owc-select-dropdown"]],
  inputs: {
    applyButtonText: "applyButtonText",
    clearButtonProps: "clearButtonProps",
    disableValidation: "disableValidation",
    disabled: "disabled",
    hideSelectAll: "hideSelectAll",
    inputProps: "inputProps",
    label: "label",
    limit: "limit",
    maxlength: "maxlength",
    menuProps: "menuProps",
    menuTriggerButtonProps: "menuTriggerButtonProps",
    minlength: "minlength",
    multiple: "multiple",
    name: "name",
    noClearIcon: "noClearIcon",
    notFoundText: "notFoundText",
    notSelectableValues: "notSelectableValues",
    placeholder: "placeholder",
    required: "required",
    rounded: "rounded",
    searchable: "searchable",
    selectAllText: "selectAllText",
    selectableValues: "selectableValues",
    showApplyButton: "showApplyButton",
    size: "size",
    tooltipProps: "tooltipProps",
    validity: "validity",
    value: "value",
    variant: "variant",
    visible: "visible"
  },
  outputs: {
    clearClick: "clearClick",
    clickOutside: "clickOutside",
    validityChange: "validityChange",
    valueChange: "valueChange",
    visibleChange: "visibleChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcSelectDropdown_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcSelectDropdown = __decorate([ProxyCmp({
  inputs: ['applyButtonText', 'clearButtonProps', 'disableValidation', 'disabled', 'hideSelectAll', 'inputProps', 'label', 'limit', 'maxlength', 'menuProps', 'menuTriggerButtonProps', 'minlength', 'multiple', 'name', 'noClearIcon', 'notFoundText', 'notSelectableValues', 'placeholder', 'required', 'rounded', 'searchable', 'selectAllText', 'selectableValues', 'showApplyButton', 'size', 'tooltipProps', 'validity', 'value', 'variant', 'visible'],
  methods: ['validate']
})], OwcSelectDropdown);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcSelectDropdown, [{
    type: Component,
    args: [{
      selector: 'owc-select-dropdown',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['applyButtonText', 'clearButtonProps', 'disableValidation', 'disabled', 'hideSelectAll', 'inputProps', 'label', 'limit', 'maxlength', 'menuProps', 'menuTriggerButtonProps', 'minlength', 'multiple', 'name', 'noClearIcon', 'notFoundText', 'notSelectableValues', 'placeholder', 'required', 'rounded', 'searchable', 'selectAllText', 'selectableValues', 'showApplyButton', 'size', 'tooltipProps', 'validity', 'value', 'variant', 'visible'],
      outputs: ['clearClick', 'clickOutside', 'validityChange', 'valueChange', 'visibleChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcSideMenu = class OwcSideMenu {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcSideMenu.ɵfac = function OwcSideMenu_Factory(t) {
  return new (t || OwcSideMenu)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcSideMenu.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcSideMenu,
  selectors: [["owc-side-menu"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcSideMenu_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcSideMenu = __decorate([ProxyCmp({
  inputs: []
})], OwcSideMenu);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcSideMenu, [{
    type: Component,
    args: [{
      selector: 'owc-side-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcSkeletonLoader = class OwcSkeletonLoader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcSkeletonLoader.ɵfac = function OwcSkeletonLoader_Factory(t) {
  return new (t || OwcSkeletonLoader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcSkeletonLoader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcSkeletonLoader,
  selectors: [["owc-skeleton-loader"]],
  inputs: {
    animation: "animation",
    variant: "variant"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcSkeletonLoader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcSkeletonLoader = __decorate([ProxyCmp({
  inputs: ['animation', 'variant']
})], OwcSkeletonLoader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcSkeletonLoader, [{
    type: Component,
    args: [{
      selector: 'owc-skeleton-loader',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['animation', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcSlider = class OwcSlider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['slide', 'valueChange']);
  }
};
OwcSlider.ɵfac = function OwcSlider_Factory(t) {
  return new (t || OwcSlider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcSlider.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcSlider,
  selectors: [["owc-slider"]],
  inputs: {
    accessibilityProps: "accessibilityProps",
    direction: "direction",
    disabled: "disabled",
    hostAriaLabel: "hostAriaLabel",
    inverted: "inverted",
    max: "max",
    min: "min",
    name: "name",
    shiftValue: "shiftValue",
    showValueLabel: "showValueLabel",
    step: "step",
    tickIntervals: "tickIntervals",
    ticks: "ticks",
    value: "value"
  },
  outputs: {
    slide: "slide",
    valueChange: "valueChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcSlider_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcSlider = __decorate([ProxyCmp({
  inputs: ['accessibilityProps', 'direction', 'disabled', 'hostAriaLabel', 'inverted', 'max', 'min', 'name', 'shiftValue', 'showValueLabel', 'step', 'tickIntervals', 'ticks', 'value']
})], OwcSlider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcSlider, [{
    type: Component,
    args: [{
      selector: 'owc-slider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['accessibilityProps', 'direction', 'disabled', 'hostAriaLabel', 'inverted', 'max', 'min', 'name', 'shiftValue', 'showValueLabel', 'step', 'tickIntervals', 'ticks', 'value'],
      outputs: ['slide', 'valueChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcSliderMarker = class OwcSliderMarker {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcSliderMarker.ɵfac = function OwcSliderMarker_Factory(t) {
  return new (t || OwcSliderMarker)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcSliderMarker.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcSliderMarker,
  selectors: [["owc-slider-marker"]],
  inputs: {
    disabled: "disabled",
    selected: "selected",
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcSliderMarker_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcSliderMarker = __decorate([ProxyCmp({
  inputs: ['disabled', 'selected', 'value']
})], OwcSliderMarker);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcSliderMarker, [{
    type: Component,
    args: [{
      selector: 'owc-slider-marker',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'selected', 'value']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcSnackbar = class OwcSnackbar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['close', 'open']);
  }
};
OwcSnackbar.ɵfac = function OwcSnackbar_Factory(t) {
  return new (t || OwcSnackbar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcSnackbar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcSnackbar,
  selectors: [["owc-snackbar"]],
  inputs: {
    customTheme: "customTheme",
    direction: "direction",
    dismissIconProps: "dismissIconProps",
    duration: "duration",
    elevated: "elevated",
    horizontalPlacement: "horizontalPlacement",
    showClose: "showClose",
    status: "status",
    theme: "theme",
    variant: "variant",
    verticalPlacement: "verticalPlacement"
  },
  outputs: {
    close: "close",
    open: "open"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcSnackbar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcSnackbar = __decorate([ProxyCmp({
  inputs: ['customTheme', 'direction', 'dismissIconProps', 'duration', 'elevated', 'horizontalPlacement', 'showClose', 'status', 'theme', 'variant', 'verticalPlacement'],
  methods: ['hide']
})], OwcSnackbar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcSnackbar, [{
    type: Component,
    args: [{
      selector: 'owc-snackbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['customTheme', 'direction', 'dismissIconProps', 'duration', 'elevated', 'horizontalPlacement', 'showClose', 'status', 'theme', 'variant', 'verticalPlacement'],
      outputs: ['close', 'open']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcSplitScreen = class OwcSplitScreen {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['resizing', 'sizeChange']);
  }
};
OwcSplitScreen.ɵfac = function OwcSplitScreen_Factory(t) {
  return new (t || OwcSplitScreen)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcSplitScreen.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcSplitScreen,
  selectors: [["owc-split-screen"]],
  inputs: {
    direction: "direction",
    disabled: "disabled",
    size: "size"
  },
  outputs: {
    resizing: "resizing",
    sizeChange: "sizeChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcSplitScreen_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcSplitScreen = __decorate([ProxyCmp({
  inputs: ['direction', 'disabled', 'size']
})], OwcSplitScreen);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcSplitScreen, [{
    type: Component,
    args: [{
      selector: 'owc-split-screen',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['direction', 'disabled', 'size'],
      outputs: ['resizing', 'sizeChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcStep = class OwcStep {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcStep.ɵfac = function OwcStep_Factory(t) {
  return new (t || OwcStep)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcStep.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcStep,
  selectors: [["owc-step"]],
  inputs: {
    direction: "direction",
    disabled: "disabled",
    dividerProps: "dividerProps",
    linear: "linear",
    status: "status",
    step: "step"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcStep_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcStep = __decorate([ProxyCmp({
  inputs: ['direction', 'disabled', 'dividerProps', 'linear', 'status', 'step']
})], OwcStep);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcStep, [{
    type: Component,
    args: [{
      selector: 'owc-step',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['direction', 'disabled', 'dividerProps', 'linear', 'status', 'step']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcStepper = class OwcStepper {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['activeStepChange']);
  }
};
OwcStepper.ɵfac = function OwcStepper_Factory(t) {
  return new (t || OwcStepper)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcStepper.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcStepper,
  selectors: [["owc-stepper"]],
  inputs: {
    activeStep: "activeStep",
    direction: "direction",
    linear: "linear"
  },
  outputs: {
    activeStepChange: "activeStepChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcStepper_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcStepper = __decorate([ProxyCmp({
  inputs: ['activeStep', 'direction', 'linear']
})], OwcStepper);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcStepper, [{
    type: Component,
    args: [{
      selector: 'owc-stepper',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['activeStep', 'direction', 'linear'],
      outputs: ['activeStepChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcSwitch = class OwcSwitch {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
};
OwcSwitch.ɵfac = function OwcSwitch_Factory(t) {
  return new (t || OwcSwitch)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcSwitch.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcSwitch,
  selectors: [["owc-switch"]],
  inputs: {
    checked: "checked",
    controlled: "controlled",
    disableRipple: "disableRipple",
    disabled: "disabled",
    hostId: "hostId",
    labelPlacement: "labelPlacement",
    name: "name",
    nativeInputProps: "nativeInputProps",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcSwitch_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcSwitch = __decorate([ProxyCmp({
  inputs: ['checked', 'controlled', 'disableRipple', 'disabled', 'hostId', 'labelPlacement', 'name', 'nativeInputProps', 'value']
})], OwcSwitch);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcSwitch, [{
    type: Component,
    args: [{
      selector: 'owc-switch',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['checked', 'controlled', 'disableRipple', 'disabled', 'hostId', 'labelPlacement', 'name', 'nativeInputProps', 'value'],
      outputs: ['valueChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcTab = class OwcTab {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcTab.ɵfac = function OwcTab_Factory(t) {
  return new (t || OwcTab)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTab.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTab,
  selectors: [["owc-tab"]],
  inputs: {
    direction: "direction",
    disableRipple: "disableRipple",
    disabled: "disabled",
    selected: "selected",
    size: "size",
    value: "value",
    variant: "variant"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTab_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTab = __decorate([ProxyCmp({
  inputs: ['direction', 'disableRipple', 'disabled', 'selected', 'size', 'value', 'variant']
})], OwcTab);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTab, [{
    type: Component,
    args: [{
      selector: 'owc-tab',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['direction', 'disableRipple', 'disabled', 'selected', 'size', 'value', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcTable = class OwcTable {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['scrollChange', 'selectedRowsChange']);
  }
};
OwcTable.ɵfac = function OwcTable_Factory(t) {
  return new (t || OwcTable)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTable.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTable,
  selectors: [["owc-table"]],
  inputs: {
    alignRowWithGroup: "alignRowWithGroup",
    alternate: "alternate",
    cardProps: "cardProps",
    elevated: "elevated",
    loading: "loading",
    rounded: "rounded",
    showGroupExpanderColumn: "showGroupExpanderColumn",
    showRowExpanderColumn: "showRowExpanderColumn",
    showSelectionColumn: "showSelectionColumn",
    size: "size",
    sortableRows: "sortableRows",
    stickyColumnBeginning: "stickyColumnBeginning",
    stickyColumnEnd: "stickyColumnEnd",
    verticalBorder: "verticalBorder"
  },
  outputs: {
    scrollChange: "scrollChange",
    selectedRowsChange: "selectedRowsChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTable_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTable = __decorate([ProxyCmp({
  inputs: ['alignRowWithGroup', 'alternate', 'cardProps', 'elevated', 'loading', 'rounded', 'showGroupExpanderColumn', 'showRowExpanderColumn', 'showSelectionColumn', 'size', 'sortableRows', 'stickyColumnBeginning', 'stickyColumnEnd', 'verticalBorder'],
  methods: ['scrollToBottom', 'scrollToLeft', 'scrollToRight', 'scrollToTop']
})], OwcTable);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTable, [{
    type: Component,
    args: [{
      selector: 'owc-table',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['alignRowWithGroup', 'alternate', 'cardProps', 'elevated', 'loading', 'rounded', 'showGroupExpanderColumn', 'showRowExpanderColumn', 'showSelectionColumn', 'size', 'sortableRows', 'stickyColumnBeginning', 'stickyColumnEnd', 'verticalBorder'],
      outputs: ['scrollChange', 'selectedRowsChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcTableBody = class OwcTableBody {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcTableBody.ɵfac = function OwcTableBody_Factory(t) {
  return new (t || OwcTableBody)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTableBody.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTableBody,
  selectors: [["owc-table-body"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTableBody_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTableBody = __decorate([ProxyCmp({
  inputs: []
})], OwcTableBody);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTableBody, [{
    type: Component,
    args: [{
      selector: 'owc-table-body',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcTableCell = class OwcTableCell {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['contentChange', 'editingChange']);
  }
};
OwcTableCell.ɵfac = function OwcTableCell_Factory(t) {
  return new (t || OwcTableCell)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTableCell.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTableCell,
  selectors: [["owc-table-cell"]],
  inputs: {
    align: "align",
    editable: "editable",
    editing: "editing",
    sticky: "sticky",
    valign: "valign"
  },
  outputs: {
    contentChange: "contentChange",
    editingChange: "editingChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTableCell_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTableCell = __decorate([ProxyCmp({
  inputs: ['align', 'editable', 'editing', 'sticky', 'valign']
})], OwcTableCell);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTableCell, [{
    type: Component,
    args: [{
      selector: 'owc-table-cell',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['align', 'editable', 'editing', 'sticky', 'valign'],
      outputs: ['contentChange', 'editingChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcTableGroup = class OwcTableGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['expandChange', 'selectChange']);
  }
};
OwcTableGroup.ɵfac = function OwcTableGroup_Factory(t) {
  return new (t || OwcTableGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTableGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTableGroup,
  selectors: [["owc-table-group"]],
  inputs: {
    defaultIndentation: "defaultIndentation",
    disabled: "disabled",
    expandButtonProps: "expandButtonProps",
    expandable: "expandable",
    expanded: "expanded",
    header: "header",
    indentation: "indentation",
    selectable: "selectable",
    selected: "selected",
    sticky: "sticky"
  },
  outputs: {
    expandChange: "expandChange",
    selectChange: "selectChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTableGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTableGroup = __decorate([ProxyCmp({
  inputs: ['defaultIndentation', 'disabled', 'expandButtonProps', 'expandable', 'expanded', 'header', 'indentation', 'selectable', 'selected', 'sticky']
})], OwcTableGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTableGroup, [{
    type: Component,
    args: [{
      selector: 'owc-table-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['defaultIndentation', 'disabled', 'expandButtonProps', 'expandable', 'expanded', 'header', 'indentation', 'selectable', 'selected', 'sticky'],
      outputs: ['expandChange', 'selectChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcTableHeader = class OwcTableHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcTableHeader.ɵfac = function OwcTableHeader_Factory(t) {
  return new (t || OwcTableHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTableHeader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTableHeader,
  selectors: [["owc-table-header"]],
  inputs: {
    sticky: "sticky"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTableHeader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTableHeader = __decorate([ProxyCmp({
  inputs: ['sticky']
})], OwcTableHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTableHeader, [{
    type: Component,
    args: [{
      selector: 'owc-table-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['sticky']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcTableHeaderCell = class OwcTableHeaderCell {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dragResize', 'sortOrderChange', 'widthChange']);
  }
};
OwcTableHeaderCell.ɵfac = function OwcTableHeaderCell_Factory(t) {
  return new (t || OwcTableHeaderCell)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTableHeaderCell.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTableHeaderCell,
  selectors: [["owc-table-header-cell"]],
  inputs: {
    align: "align",
    disabled: "disabled",
    notFocusable: "notFocusable",
    resizable: "resizable",
    sortIconProps: "sortIconProps",
    sortOrder: "sortOrder",
    sortable: "sortable",
    sticky: "sticky",
    valign: "valign",
    width: "width",
    wrap: "wrap"
  },
  outputs: {
    dragResize: "dragResize",
    sortOrderChange: "sortOrderChange",
    widthChange: "widthChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTableHeaderCell_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTableHeaderCell = __decorate([ProxyCmp({
  inputs: ['align', 'disabled', 'notFocusable', 'resizable', 'sortIconProps', 'sortOrder', 'sortable', 'sticky', 'valign', 'width', 'wrap']
})], OwcTableHeaderCell);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTableHeaderCell, [{
    type: Component,
    args: [{
      selector: 'owc-table-header-cell',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['align', 'disabled', 'notFocusable', 'resizable', 'sortIconProps', 'sortOrder', 'sortable', 'sticky', 'valign', 'width', 'wrap'],
      outputs: ['dragResize', 'sortOrderChange', 'widthChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcTableRow = class OwcTableRow {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['expandedChange', 'indexChange', 'selectedChange']);
  }
};
OwcTableRow.ɵfac = function OwcTableRow_Factory(t) {
  return new (t || OwcTableRow)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTableRow.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTableRow,
  selectors: [["owc-table-row"]],
  inputs: {
    disabled: "disabled",
    expandButtonProps: "expandButtonProps",
    expandable: "expandable",
    expanded: "expanded",
    locked: "locked",
    selectable: "selectable",
    selected: "selected"
  },
  outputs: {
    expandedChange: "expandedChange",
    indexChange: "indexChange",
    selectedChange: "selectedChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTableRow_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTableRow = __decorate([ProxyCmp({
  inputs: ['disabled', 'expandButtonProps', 'expandable', 'expanded', 'locked', 'selectable', 'selected']
})], OwcTableRow);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTableRow, [{
    type: Component,
    args: [{
      selector: 'owc-table-row',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'expandButtonProps', 'expandable', 'expanded', 'locked', 'selectable', 'selected'],
      outputs: ['expandedChange', 'indexChange', 'selectedChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcTableSimpleCell = class OwcTableSimpleCell {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcTableSimpleCell.ɵfac = function OwcTableSimpleCell_Factory(t) {
  return new (t || OwcTableSimpleCell)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTableSimpleCell.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTableSimpleCell,
  selectors: [["owc-table-simple-cell"]],
  inputs: {
    align: "align",
    sticky: "sticky",
    valign: "valign"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTableSimpleCell_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTableSimpleCell = __decorate([ProxyCmp({
  inputs: ['align', 'sticky', 'valign']
})], OwcTableSimpleCell);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTableSimpleCell, [{
    type: Component,
    args: [{
      selector: 'owc-table-simple-cell',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['align', 'sticky', 'valign']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcTabs = class OwcTabs {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
};
OwcTabs.ɵfac = function OwcTabs_Factory(t) {
  return new (t || OwcTabs)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTabs.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTabs,
  selectors: [["owc-tabs"]],
  inputs: {
    direction: "direction",
    disableRipple: "disableRipple",
    divider: "divider",
    elevated: "elevated",
    size: "size",
    value: "value",
    variant: "variant"
  },
  outputs: {
    valueChange: "valueChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTabs_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTabs = __decorate([ProxyCmp({
  inputs: ['direction', 'disableRipple', 'divider', 'elevated', 'size', 'value', 'variant']
})], OwcTabs);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTabs, [{
    type: Component,
    args: [{
      selector: 'owc-tabs',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['direction', 'disableRipple', 'divider', 'elevated', 'size', 'value', 'variant'],
      outputs: ['valueChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcTag = class OwcTag {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcTag.ɵfac = function OwcTag_Factory(t) {
  return new (t || OwcTag)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTag.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTag,
  selectors: [["owc-tag"]],
  inputs: {
    disabled: "disabled",
    outlined: "outlined",
    rounded: "rounded"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTag_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTag = __decorate([ProxyCmp({
  inputs: ['disabled', 'outlined', 'rounded']
})], OwcTag);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTag, [{
    type: Component,
    args: [{
      selector: 'owc-tag',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'outlined', 'rounded']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcThemeProvider = class OwcThemeProvider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcThemeProvider.ɵfac = function OwcThemeProvider_Factory(t) {
  return new (t || OwcThemeProvider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcThemeProvider.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcThemeProvider,
  selectors: [["owc-theme-provider"]],
  inputs: {
    customTheme: "customTheme",
    targetElement: "targetElement",
    theme: "theme"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcThemeProvider_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcThemeProvider = __decorate([ProxyCmp({
  inputs: ['customTheme', 'targetElement', 'theme']
})], OwcThemeProvider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcThemeProvider, [{
    type: Component,
    args: [{
      selector: 'owc-theme-provider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['customTheme', 'targetElement', 'theme']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcTimepicker = class OwcTimepicker {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['clearClick', 'clickOutside', 'inputValueChange', 'validityChange', 'valueChange', 'visibleChange']);
  }
};
OwcTimepicker.ɵfac = function OwcTimepicker_Factory(t) {
  return new (t || OwcTimepicker)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTimepicker.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTimepicker,
  selectors: [["owc-timepicker"]],
  inputs: {
    disableValidation: "disableValidation",
    disabled: "disabled",
    format: "format",
    inputProps: "inputProps",
    label: "label",
    locale: "locale",
    maxTime: "maxTime",
    menuProps: "menuProps",
    minTime: "minTime",
    name: "name",
    native: "native",
    noClearIcon: "noClearIcon",
    placeholder: "placeholder",
    required: "required",
    selectOutOfStep: "selectOutOfStep",
    size: "size",
    step: "step",
    unavailable: "unavailable",
    validity: "validity",
    value: "value",
    variant: "variant",
    visible: "visible"
  },
  outputs: {
    clearClick: "clearClick",
    clickOutside: "clickOutside",
    inputValueChange: "inputValueChange",
    validityChange: "validityChange",
    valueChange: "valueChange",
    visibleChange: "visibleChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTimepicker_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTimepicker = __decorate([ProxyCmp({
  inputs: ['disableValidation', 'disabled', 'format', 'inputProps', 'label', 'locale', 'maxTime', 'menuProps', 'minTime', 'name', 'native', 'noClearIcon', 'placeholder', 'required', 'selectOutOfStep', 'size', 'step', 'unavailable', 'validity', 'value', 'variant', 'visible'],
  methods: ['validate']
})], OwcTimepicker);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTimepicker, [{
    type: Component,
    args: [{
      selector: 'owc-timepicker',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disableValidation', 'disabled', 'format', 'inputProps', 'label', 'locale', 'maxTime', 'menuProps', 'minTime', 'name', 'native', 'noClearIcon', 'placeholder', 'required', 'selectOutOfStep', 'size', 'step', 'unavailable', 'validity', 'value', 'variant', 'visible'],
      outputs: ['clearClick', 'clickOutside', 'inputValueChange', 'validityChange', 'valueChange', 'visibleChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcToggleButton = class OwcToggleButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcToggleButton.ɵfac = function OwcToggleButton_Factory(t) {
  return new (t || OwcToggleButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcToggleButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcToggleButton,
  selectors: [["owc-toggle-button"]],
  inputs: {
    disableRipple: "disableRipple",
    disabled: "disabled",
    groupDisabled: "groupDisabled",
    selected: "selected",
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcToggleButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcToggleButton = __decorate([ProxyCmp({
  inputs: ['disableRipple', 'disabled', 'groupDisabled', 'selected', 'value']
})], OwcToggleButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcToggleButton, [{
    type: Component,
    args: [{
      selector: 'owc-toggle-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disableRipple', 'disabled', 'groupDisabled', 'selected', 'value']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcToggleButtonGroup = class OwcToggleButtonGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
};
OwcToggleButtonGroup.ɵfac = function OwcToggleButtonGroup_Factory(t) {
  return new (t || OwcToggleButtonGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcToggleButtonGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcToggleButtonGroup,
  selectors: [["owc-toggle-button-group"]],
  inputs: {
    disabled: "disabled",
    elevated: "elevated",
    exclusive: "exclusive",
    rounded: "rounded",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcToggleButtonGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcToggleButtonGroup = __decorate([ProxyCmp({
  inputs: ['disabled', 'elevated', 'exclusive', 'rounded', 'value']
})], OwcToggleButtonGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcToggleButtonGroup, [{
    type: Component,
    args: [{
      selector: 'owc-toggle-button-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'elevated', 'exclusive', 'rounded', 'value'],
      outputs: ['valueChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcTooltip = class OwcTooltip {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['clickOutside', 'visibleChange']);
  }
};
OwcTooltip.ɵfac = function OwcTooltip_Factory(t) {
  return new (t || OwcTooltip)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTooltip.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTooltip,
  selectors: [["owc-tooltip"]],
  inputs: {
    anchor: "anchor",
    disableTriggers: "disableTriggers",
    enterDelay: "enterDelay",
    enterDuration: "enterDuration",
    hostId: "hostId",
    leaveDelay: "leaveDelay",
    leaveDuration: "leaveDuration",
    offset: "offset",
    placement: "placement",
    popoverProps: "popoverProps",
    size: "size",
    strategy: "strategy",
    variant: "variant",
    visible: "visible"
  },
  outputs: {
    clickOutside: "clickOutside",
    visibleChange: "visibleChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTooltip_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTooltip = __decorate([ProxyCmp({
  inputs: ['anchor', 'disableTriggers', 'enterDelay', 'enterDuration', 'hostId', 'leaveDelay', 'leaveDuration', 'offset', 'placement', 'popoverProps', 'size', 'strategy', 'variant', 'visible']
})], OwcTooltip);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTooltip, [{
    type: Component,
    args: [{
      selector: 'owc-tooltip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['anchor', 'disableTriggers', 'enterDelay', 'enterDuration', 'hostId', 'leaveDelay', 'leaveDuration', 'offset', 'placement', 'popoverProps', 'size', 'strategy', 'variant', 'visible'],
      outputs: ['clickOutside', 'visibleChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcTypography = class OwcTypography {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcTypography.ɵfac = function OwcTypography_Factory(t) {
  return new (t || OwcTypography)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTypography.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTypography,
  selectors: [["owc-typography"]],
  inputs: {
    device: "device",
    element: "element",
    mode: "mode",
    variant: "variant"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTypography_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTypography = __decorate([ProxyCmp({
  inputs: ['device', 'element', 'mode', 'variant']
})], OwcTypography);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTypography, [{
    type: Component,
    args: [{
      selector: 'owc-typography',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['device', 'element', 'mode', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcWorkflowOverlay = class OwcWorkflowOverlay {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['visibleChange']);
  }
};
OwcWorkflowOverlay.ɵfac = function OwcWorkflowOverlay_Factory(t) {
  return new (t || OwcWorkflowOverlay)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcWorkflowOverlay.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcWorkflowOverlay,
  selectors: [["owc-workflow-overlay"]],
  inputs: {
    backdropProps: "backdropProps",
    cardProps: "cardProps",
    closeIconProps: "closeIconProps",
    disableBackdropClick: "disableBackdropClick",
    disableEscapeKeydown: "disableEscapeKeydown",
    height: "height",
    hideBackdrop: "hideBackdrop",
    noCloseIcon: "noCloseIcon",
    visible: "visible",
    width: "width"
  },
  outputs: {
    visibleChange: "visibleChange"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcWorkflowOverlay_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcWorkflowOverlay = __decorate([ProxyCmp({
  inputs: ['backdropProps', 'cardProps', 'closeIconProps', 'disableBackdropClick', 'disableEscapeKeydown', 'height', 'hideBackdrop', 'noCloseIcon', 'visible', 'width']
})], OwcWorkflowOverlay);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcWorkflowOverlay, [{
    type: Component,
    args: [{
      selector: 'owc-workflow-overlay',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['backdropProps', 'cardProps', 'closeIconProps', 'disableBackdropClick', 'disableEscapeKeydown', 'height', 'hideBackdrop', 'noCloseIcon', 'visible', 'width'],
      outputs: ['visibleChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let OwcYearCalendar = class OwcYearCalendar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dateSelect', 'visibleDateChange', 'yearCalendarFocusout']);
  }
};
OwcYearCalendar.ɵfac = function OwcYearCalendar_Factory(t) {
  return new (t || OwcYearCalendar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcYearCalendar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcYearCalendar,
  selectors: [["owc-year-calendar"]],
  inputs: {
    disabled: "disabled",
    disabledDates: "disabledDates",
    from: "from",
    hideCurrent: "hideCurrent",
    locale: "locale",
    max: "max",
    min: "min",
    quick: "quick",
    to: "to",
    visibleDate: "visibleDate"
  },
  outputs: {
    dateSelect: "dateSelect",
    visibleDateChange: "visibleDateChange",
    yearCalendarFocusout: "yearCalendarFocusout"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcYearCalendar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcYearCalendar = __decorate([ProxyCmp({
  inputs: ['disabled', 'disabledDates', 'from', 'hideCurrent', 'locale', 'max', 'min', 'quick', 'to', 'visibleDate']
})], OwcYearCalendar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcYearCalendar, [{
    type: Component,
    args: [{
      selector: 'owc-year-calendar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['disabled', 'disabledDates', 'from', 'hideCurrent', 'locale', 'max', 'min', 'quick', 'to', 'visibleDate'],
      outputs: ['dateSelect', 'visibleDateChange', 'yearCalendarFocusout']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
class OwcBaseDropdownAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('value', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcBaseDropdownAccessor.ɵfac = function OwcBaseDropdownAccessor_Factory(t) {
  return new (t || OwcBaseDropdownAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcBaseDropdownAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcBaseDropdownAccessor,
  selectors: [["owc-base-dropdown"]],
  hostBindings: function OwcBaseDropdownAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function OwcBaseDropdownAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcBaseDropdownAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcBaseDropdownAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcBaseDropdownAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-base-dropdown',
      host: {
        '(valueChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcBaseDropdownAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcCheckboxAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('checked', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcCheckboxAccessor.ɵfac = function OwcCheckboxAccessor_Factory(t) {
  return new (t || OwcCheckboxAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcCheckboxAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcCheckboxAccessor,
  selectors: [["owc-checkbox"]],
  hostBindings: function OwcCheckboxAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function OwcCheckboxAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcCheckboxAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcCheckboxAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcCheckboxAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-checkbox',
      host: {
        '(valueChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcCheckboxAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcDatepickerAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('value', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcDatepickerAccessor.ɵfac = function OwcDatepickerAccessor_Factory(t) {
  return new (t || OwcDatepickerAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcDatepickerAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcDatepickerAccessor,
  selectors: [["owc-datepicker"]],
  hostBindings: function OwcDatepickerAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function OwcDatepickerAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcDatepickerAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcDatepickerAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcDatepickerAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-datepicker',
      host: {
        '(valueChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcDatepickerAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcDotAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('selected', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcDotAccessor.ɵfac = function OwcDotAccessor_Factory(t) {
  return new (t || OwcDotAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcDotAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcDotAccessor,
  selectors: [["owc-dot"]],
  hostBindings: function OwcDotAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("selectedChange", function OwcDotAccessor_selectedChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcDotAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcDotAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcDotAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-dot',
      host: {
        '(selectedChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcDotAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcDotsAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('selected', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcDotsAccessor.ɵfac = function OwcDotsAccessor_Factory(t) {
  return new (t || OwcDotsAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcDotsAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcDotsAccessor,
  selectors: [["owc-dots"]],
  hostBindings: function OwcDotsAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("selectedChange", function OwcDotsAccessor_selectedChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcDotsAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcDotsAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcDotsAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-dots',
      host: {
        '(selectedChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcDotsAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcDrawerAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('visible', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcDrawerAccessor.ɵfac = function OwcDrawerAccessor_Factory(t) {
  return new (t || OwcDrawerAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcDrawerAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcDrawerAccessor,
  selectors: [["owc-drawer"]],
  hostBindings: function OwcDrawerAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("visibleChange", function OwcDrawerAccessor_visibleChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcDrawerAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcDrawerAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcDrawerAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-drawer',
      host: {
        '(visibleChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcDrawerAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcEditableDropdownAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('value', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcEditableDropdownAccessor.ɵfac = function OwcEditableDropdownAccessor_Factory(t) {
  return new (t || OwcEditableDropdownAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcEditableDropdownAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcEditableDropdownAccessor,
  selectors: [["owc-editable-dropdown"]],
  hostBindings: function OwcEditableDropdownAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function OwcEditableDropdownAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcEditableDropdownAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcEditableDropdownAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcEditableDropdownAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-editable-dropdown',
      host: {
        '(valueChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcEditableDropdownAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcExpandableAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('expanded', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcExpandableAccessor.ɵfac = function OwcExpandableAccessor_Factory(t) {
  return new (t || OwcExpandableAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcExpandableAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcExpandableAccessor,
  selectors: [["owc-expandable"]],
  hostBindings: function OwcExpandableAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("expandedChange", function OwcExpandableAccessor_expandedChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcExpandableAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcExpandableAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcExpandableAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-expandable',
      host: {
        '(expandedChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcExpandableAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcInputAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('value', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcInputAccessor.ɵfac = function OwcInputAccessor_Factory(t) {
  return new (t || OwcInputAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcInputAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcInputAccessor,
  selectors: [["owc-input"]],
  hostBindings: function OwcInputAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function OwcInputAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcInputAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcInputAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcInputAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-input',
      host: {
        '(valueChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcInputAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcListAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('selected', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcListAccessor.ɵfac = function OwcListAccessor_Factory(t) {
  return new (t || OwcListAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcListAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcListAccessor,
  selectors: [["owc-list"]],
  hostBindings: function OwcListAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("selectedChange", function OwcListAccessor_selectedChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcListAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcListAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcListAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-list',
      host: {
        '(selectedChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcListAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcMenuAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('visible', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcMenuAccessor.ɵfac = function OwcMenuAccessor_Factory(t) {
  return new (t || OwcMenuAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcMenuAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcMenuAccessor,
  selectors: [["owc-menu"]],
  hostBindings: function OwcMenuAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("visibleChange", function OwcMenuAccessor_visibleChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcMenuAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcMenuAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcMenuAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-menu',
      host: {
        '(visibleChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcMenuAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcModalDialogAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('visible', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcModalDialogAccessor.ɵfac = function OwcModalDialogAccessor_Factory(t) {
  return new (t || OwcModalDialogAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcModalDialogAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcModalDialogAccessor,
  selectors: [["owc-modal-dialog"]],
  hostBindings: function OwcModalDialogAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("visibleChange", function OwcModalDialogAccessor_visibleChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcModalDialogAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcModalDialogAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcModalDialogAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-modal-dialog',
      host: {
        '(visibleChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcModalDialogAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcRadioAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('checked', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcRadioAccessor.ɵfac = function OwcRadioAccessor_Factory(t) {
  return new (t || OwcRadioAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcRadioAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcRadioAccessor,
  selectors: [["owc-radio"]],
  hostBindings: function OwcRadioAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function OwcRadioAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcRadioAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcRadioAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcRadioAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-radio',
      host: {
        '(valueChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcRadioAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcRadioGroupAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('value', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcRadioGroupAccessor.ɵfac = function OwcRadioGroupAccessor_Factory(t) {
  return new (t || OwcRadioGroupAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcRadioGroupAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcRadioGroupAccessor,
  selectors: [["owc-radio-group"]],
  hostBindings: function OwcRadioGroupAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function OwcRadioGroupAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcRadioGroupAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcRadioGroupAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcRadioGroupAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-radio-group',
      host: {
        '(valueChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcRadioGroupAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcSelectDropdownAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('value', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcSelectDropdownAccessor.ɵfac = function OwcSelectDropdownAccessor_Factory(t) {
  return new (t || OwcSelectDropdownAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcSelectDropdownAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcSelectDropdownAccessor,
  selectors: [["owc-select-dropdown"]],
  hostBindings: function OwcSelectDropdownAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function OwcSelectDropdownAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcSelectDropdownAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcSelectDropdownAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcSelectDropdownAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-select-dropdown',
      host: {
        '(valueChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcSelectDropdownAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcSliderAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('value', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcSliderAccessor.ɵfac = function OwcSliderAccessor_Factory(t) {
  return new (t || OwcSliderAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcSliderAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcSliderAccessor,
  selectors: [["owc-slider"]],
  hostBindings: function OwcSliderAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function OwcSliderAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcSliderAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcSliderAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcSliderAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-slider',
      host: {
        '(valueChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcSliderAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcSplitScreenAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('size', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcSplitScreenAccessor.ɵfac = function OwcSplitScreenAccessor_Factory(t) {
  return new (t || OwcSplitScreenAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcSplitScreenAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcSplitScreenAccessor,
  selectors: [["owc-split-screen"]],
  hostBindings: function OwcSplitScreenAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("sizeChange", function OwcSplitScreenAccessor_sizeChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcSplitScreenAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcSplitScreenAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcSplitScreenAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-split-screen',
      host: {
        '(sizeChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcSplitScreenAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcStepperAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('activeStep', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcStepperAccessor.ɵfac = function OwcStepperAccessor_Factory(t) {
  return new (t || OwcStepperAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcStepperAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcStepperAccessor,
  selectors: [["owc-stepper"]],
  hostBindings: function OwcStepperAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("activeStepChange", function OwcStepperAccessor_activeStepChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcStepperAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcStepperAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcStepperAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-stepper',
      host: {
        '(activeStepChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcStepperAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcSwitchAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('checked', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcSwitchAccessor.ɵfac = function OwcSwitchAccessor_Factory(t) {
  return new (t || OwcSwitchAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcSwitchAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcSwitchAccessor,
  selectors: [["owc-switch"]],
  hostBindings: function OwcSwitchAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function OwcSwitchAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcSwitchAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcSwitchAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcSwitchAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-switch',
      host: {
        '(valueChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcSwitchAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcTabsAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('value', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcTabsAccessor.ɵfac = function OwcTabsAccessor_Factory(t) {
  return new (t || OwcTabsAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcTabsAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcTabsAccessor,
  selectors: [["owc-tabs"]],
  hostBindings: function OwcTabsAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function OwcTabsAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcTabsAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcTabsAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTabsAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-tabs',
      host: {
        '(valueChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcTabsAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcTimepickerAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('value', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcTimepickerAccessor.ɵfac = function OwcTimepickerAccessor_Factory(t) {
  return new (t || OwcTimepickerAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcTimepickerAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcTimepickerAccessor,
  selectors: [["owc-timepicker"]],
  hostBindings: function OwcTimepickerAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function OwcTimepickerAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcTimepickerAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcTimepickerAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTimepickerAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-timepicker',
      host: {
        '(valueChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcTimepickerAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcToggleButtonGroupAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('value', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcToggleButtonGroupAccessor.ɵfac = function OwcToggleButtonGroupAccessor_Factory(t) {
  return new (t || OwcToggleButtonGroupAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcToggleButtonGroupAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcToggleButtonGroupAccessor,
  selectors: [["owc-toggle-button-group"]],
  hostBindings: function OwcToggleButtonGroupAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function OwcToggleButtonGroupAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcToggleButtonGroupAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcToggleButtonGroupAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcToggleButtonGroupAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-toggle-button-group',
      host: {
        '(valueChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcToggleButtonGroupAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcTooltipAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('visible', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcTooltipAccessor.ɵfac = function OwcTooltipAccessor_Factory(t) {
  return new (t || OwcTooltipAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcTooltipAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcTooltipAccessor,
  selectors: [["owc-tooltip"]],
  hostBindings: function OwcTooltipAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("visibleChange", function OwcTooltipAccessor_visibleChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcTooltipAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcTooltipAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTooltipAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-tooltip',
      host: {
        '(visibleChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcTooltipAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcWorkflowOverlayAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('visible', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcWorkflowOverlayAccessor.ɵfac = function OwcWorkflowOverlayAccessor_Factory(t) {
  return new (t || OwcWorkflowOverlayAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcWorkflowOverlayAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcWorkflowOverlayAccessor,
  selectors: [["owc-workflow-overlay"]],
  hostBindings: function OwcWorkflowOverlayAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("visibleChange", function OwcWorkflowOverlayAccessor_visibleChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcWorkflowOverlayAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcWorkflowOverlayAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcWorkflowOverlayAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-workflow-overlay',
      host: {
        '(visibleChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcWorkflowOverlayAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
const DIRECTIVES = [OwcBaseDropdownAccessor, OwcCheckboxAccessor, OwcDatepickerAccessor, OwcDotAccessor, OwcDotsAccessor, OwcDrawerAccessor, OwcEditableDropdownAccessor, OwcExpandableAccessor, OwcInputAccessor, OwcListAccessor, OwcMenuAccessor, OwcModalDialogAccessor, OwcRadioAccessor, OwcRadioGroupAccessor, OwcSelectDropdownAccessor, OwcSliderAccessor, OwcSplitScreenAccessor, OwcStepperAccessor, OwcSwitchAccessor, OwcTabsAccessor, OwcTimepickerAccessor, OwcToggleButtonGroupAccessor, OwcTooltipAccessor, OwcWorkflowOverlayAccessor];
const COMPONENTS = [InternalLifecycleActivator, OwcAssistiveText, OwcAvatar, OwcBackdrop, OwcBadge, OwcBanner, OwcBaseDropdown, OwcBreadcrumb, OwcBreadcrumbItem, OwcButton, OwcCalendar, OwcCard, OwcCheckbox, OwcChip, OwcComponentFooter, OwcComponentHeader, OwcContactInfo, OwcCookieAcceptance, OwcCookieAcceptanceOption, OwcDatepicker, OwcDivider, OwcDot, OwcDots, OwcDrawer, OwcEditableDropdown, OwcExpandable, OwcExpandableGroup, OwcFooter, OwcGrid, OwcGridItem, OwcHeader, OwcHeaderRow, OwcHyperlink, OwcIcon, OwcIconButton, OwcIconProvider, OwcIndicatorBadge, OwcInput, OwcList, OwcListItem, OwcLogo, OwcMenu, OwcModalDialog, OwcMonthCalendar, OwcNotificationDrawer, OwcPagination, OwcPopover, OwcProgressBar, OwcProgressSpinner, OwcRadio, OwcRadioGroup, OwcSelectDropdown, OwcSideMenu, OwcSkeletonLoader, OwcSlider, OwcSliderMarker, OwcSnackbar, OwcSplitScreen, OwcStep, OwcStepper, OwcSwitch, OwcTab, OwcTable, OwcTableBody, OwcTableCell, OwcTableGroup, OwcTableHeader, OwcTableHeaderCell, OwcTableRow, OwcTableSimpleCell, OwcTabs, OwcTag, OwcThemeProvider, OwcTimepicker, OwcToggleButton, OwcToggleButtonGroup, OwcTooltip, OwcTypography, OwcWorkflowOverlay, OwcYearCalendar];
applyPolyfills().then(() => {
  defineCustomElements(window);
});
const components = [...COMPONENTS];
const modules = [];
const services = [];
const directives = [...DIRECTIVES];
const guards = [];
const pipes = [];
const providers = [];
class OneWebComponentsAngularModule {}
OneWebComponentsAngularModule.ɵfac = function OneWebComponentsAngularModule_Factory(t) {
  return new (t || OneWebComponentsAngularModule)();
};
OneWebComponentsAngularModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: OneWebComponentsAngularModule
});
OneWebComponentsAngularModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [...services, ...guards, ...providers],
  imports: [modules]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OneWebComponentsAngularModule, [{
    type: NgModule,
    args: [{
      imports: [...modules],
      exports: [...modules, ...components, ...directives, ...pipes],
      declarations: [...components, ...directives, ...pipes],
      providers: [...services, ...guards, ...providers]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DIRECTIVES, InternalLifecycleActivator, OneWebComponentsAngularModule, OwcAssistiveText, OwcAvatar, OwcBackdrop, OwcBadge, OwcBanner, OwcBaseDropdown, OwcBaseDropdownAccessor, OwcBreadcrumb, OwcBreadcrumbItem, OwcButton, OwcCalendar, OwcCard, OwcCheckbox, OwcCheckboxAccessor, OwcChip, OwcComponentFooter, OwcComponentHeader, OwcContactInfo, OwcCookieAcceptance, OwcCookieAcceptanceOption, OwcDatepicker, OwcDatepickerAccessor, OwcDivider, OwcDot, OwcDotAccessor, OwcDots, OwcDotsAccessor, OwcDrawer, OwcDrawerAccessor, OwcEditableDropdown, OwcEditableDropdownAccessor, OwcExpandable, OwcExpandableAccessor, OwcExpandableGroup, OwcFooter, OwcGrid, OwcGridItem, OwcHeader, OwcHeaderRow, OwcHyperlink, OwcIcon, OwcIconButton, OwcIconProvider, OwcIndicatorBadge, OwcInput, OwcInputAccessor, OwcList, OwcListAccessor, OwcListItem, OwcLogo, OwcMenu, OwcMenuAccessor, OwcModalDialog, OwcModalDialogAccessor, OwcMonthCalendar, OwcNotificationDrawer, OwcPagination, OwcPopover, OwcProgressBar, OwcProgressSpinner, OwcRadio, OwcRadioAccessor, OwcRadioGroup, OwcRadioGroupAccessor, OwcSelectDropdown, OwcSelectDropdownAccessor, OwcSideMenu, OwcSkeletonLoader, OwcSlider, OwcSliderAccessor, OwcSliderMarker, OwcSnackbar, OwcSplitScreen, OwcSplitScreenAccessor, OwcStep, OwcStepper, OwcStepperAccessor, OwcSwitch, OwcSwitchAccessor, OwcTab, OwcTable, OwcTableBody, OwcTableCell, OwcTableGroup, OwcTableHeader, OwcTableHeaderCell, OwcTableRow, OwcTableSimpleCell, OwcTabs, OwcTabsAccessor, OwcTag, OwcThemeProvider, OwcTimepicker, OwcTimepickerAccessor, OwcToggleButton, OwcToggleButtonGroup, OwcToggleButtonGroupAccessor, OwcTooltip, OwcTooltipAccessor, OwcTypography, OwcWorkflowOverlay, OwcWorkflowOverlayAccessor, OwcYearCalendar };
